import { useMemo } from 'react'
import { zeroAddress } from 'viem'
import { constants } from 'helpers'
import { ChainId } from 'helpers/enums'
import { usePoolsQuery, type PoolsQuery } from 'graph/liquidity'
import { useApolloClients } from 'contexts'

import usePoolsApr from './usePoolsApr'


type PoolFromQuery = PoolsQuery['pools'][number]

export type PoolResult = ChangeFields<PoolFromQuery, {
  address: Address
  token: Address
  liquidityManager: Address | null
  withdrawTimeout: number
}> & {
  isDisabled: boolean
}

const disabledPools = [ ChainId.Linea, ChainId.LineaGoerli, ChainId.Arbitrum, ChainId.ArbitrumGoerli ]

const transformPool = (pool: PoolFromQuery, poolApr: number | undefined): PoolResult => {
  const withdrawTimeout = +pool.withdrawTimeout
  const address = pool.address as Address
  const token = pool.token as Address
  const liquidityManager = pool.liquidityManager && pool.liquidityManager !== zeroAddress ? pool.liquidityManager as Address : null
  const apr = typeof poolApr === 'number' ? String(poolApr) : '--'
  const isDisabled = disabledPools.includes(pool.chainId)

  return {
    ...pool,
    address,
    token,
    liquidityManager,
    withdrawTimeout,
    apr,
    isDisabled,
  }
}

const usePools = () => {
  const clients = useApolloClients()

  const { loading: isFetchingGnosis, data: gnosisData } = usePoolsQuery({
    fetchPolicy: 'cache-and-network',
    client: clients.gnosis,
    errorPolicy: 'all',
  })

  const { loading: isFetchingPolygon, data: polygonData } = usePoolsQuery({
    fetchPolicy: 'cache-and-network',
    client: clients.polygon,
    errorPolicy: 'all',
  })

  const { loading: isFetchingChiliz, data: chilizData } = usePoolsQuery({
    fetchPolicy: 'cache-and-network',
    client: clients.chiliz,
    errorPolicy: 'all',
  })

  const { loading: isFetchingBase, data: baseData } = usePoolsQuery({
    fetchPolicy: 'cache-and-network',
    client: clients.base,
    errorPolicy: 'all',
  })

  // ATTN: we hide pools from this networks
  // const { loading: isFetchingArbitrum, data: arbitrumData } = usePoolsQuery({
  //   fetchPolicy: 'cache-and-network',
  //   client: clients.arbitrum,
  //   errorPolicy: 'all',
  // })

  // const { loading: isFetchingLinea, data: lineaData } = usePoolsQuery({
  //   fetchPolicy: 'cache-and-network',
  //   client: clients.linea,
  //   errorPolicy: 'all',
  // })

  const { data: poolsApr } = usePoolsApr()

  const pools = useMemo<PoolResult[]>(() => {
    const gnosisPools = (gnosisData?.pools || [])
      .filter(({ coreAddresses }) => !coreAddresses?.includes(constants.coreV1Address))
      .map((pool) => transformPool(pool, poolsApr?.Gnosis))

    const polygonPools = (polygonData?.pools || [])
      .map((pool) => transformPool(pool, poolsApr?.PolygonUSDT))

    const chilizPools = (chilizData?.pools || [])
      .map((pool) => transformPool(pool, poolsApr?.Chiliz))

    const basePools = (baseData?.pools || [])
      .map((pool) => transformPool(pool, poolsApr?.BaseWETH))

    // const arbitrumPools = (arbitrumData?.pools || [])
    //   .map((pool) => transformPool(pool, poolsApr?.Arbitrum))

    // const lineaPools = (lineaData?.pools || [])
    //   .map((pool) => transformPool(pool, poolsApr?.Linea))

    return [
      ...polygonPools,
      ...gnosisPools,
      ...basePools,
      ...chilizPools,
      // ...arbitrumPools,
      // ...lineaPools,
    ]
  }, [ polygonData, gnosisData, poolsApr, chilizData, baseData ])

  return {
    isFetching: isFetchingGnosis || isFetchingPolygon || isFetchingChiliz || isFetchingBase,
    pools,
  }
}

export default usePools

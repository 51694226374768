'use client'

import React from 'react'
import dynamic, { type DynamicOptionsLoadingProps } from 'next/dynamic'
import { ModalsRenderer, registerModals } from '@locmod/modal'

import { Preloader } from 'components/ui'
import InfoModal from 'compositions/app/modals/InfoModal/InfoModal'
import ErrorModal from 'compositions/app/modals/ErrorModal/ErrorModal'


export const ModalSkeleton = (loadingProps?: DynamicOptionsLoadingProps) => {
  const { isLoading = true } = loadingProps || {}

  return isLoading ? (
    <div className="fixed inset-0 m-auto -sm:mb-0 z-[99] h-56 w-full sm:max-w-[26.25rem]">
      <div className="size-full -sm:rounded-t-8 sm:rounded-8 bg-white sm:shadow-lvl3" />
      <div className="absolute inset-0 flex items-center justify-center">
        <Preloader className="w-16 h-16" />
      </div>
    </div>
  ) : null
}

const loading = ModalSkeleton

const registry = {
  ConnectModal: dynamic(() => import('compositions/app/modals/ConnectModal/ConnectModal'), { loading }),
  DepositModal: dynamic(() => import('compositions/app/modals/DepositModal/DepositModal'), { loading }),
  ErrorModal,
  InfoModal,
  RedirectModal: dynamic(() => import('compositions/app/modals/RedirectModal/RedirectModal'), { loading }),
  SuccessModal: dynamic(() => import('compositions/app/modals/SuccessModal/SuccessModal'), { loading }),
  SuccessConfettiModal: dynamic(() => import('compositions/app/modals/SuccessConfettiModal/SuccessConfettiModal'), { loading }),
  SwitchNetworkModal: dynamic(() => import('compositions/app/modals/SwitchNetworkModal/SwitchNetworkModal'), { loading }),
  WithdrawModal: dynamic(() => import('compositions/app/modals/WithdrawModal/WithdrawModal'), { loading }),
  WaitingModal: dynamic(() => import('compositions/app/modals/WaitingModal/WaitingModal'), { loading }),
  WrapModal: dynamic(() => import('compositions/app/modals/WrapModal/WrapModal'), { loading }),
  UnwrapModal: dynamic(() => import('compositions/app/modals/UnwrapModal/UnwrapModal'), { loading }),
  StakeModal: dynamic(() => import('compositions/app/modals/StakeModal/StakeModal'), { loading }),
  UnstakeModal: dynamic(() => import('compositions/app/modals/UnstakeModal/UnstakeModal'), { loading }),
  AddAzurToWalletModal: dynamic(() => import('compositions/app/modals/AddAzurToWalletModal/AddAzurToWalletModal'), { loading }),
}

registerModals(registry)

const GlobalModalsRegistrar: React.FC = () => (
  <ModalsRenderer />
)

export default GlobalModalsRegistrar

'use client'
import { createContext, useContext } from 'react'
import { ApolloClient, InMemoryCache, type TypePolicies, createHttpLink } from '@apollo/client'
import { type NormalizedCacheObject } from '@apollo/client/cache'
import { setContext } from '@apollo/client/link/context'
import { ChainId } from 'helpers/enums'


const getUri = (baseUrl: string) => ({ operationName }) => `${baseUrl}${baseUrl.includes('?') ? '&' : '?'}op=${operationName}`

const uniswapPublicGraphAuthLink = setContext((_, { headers }) => {
  const token = process.env.NEXT_PUBLIC_UNISWAP_PUBLIC_GRAPH_URL_API_KEY

  if (!token) {
    return {
      headers,
    }
  }

  return {
    headers: {
      ...headers,
      'x-api-key': token,
    },
  }
})

const gnosisTypePolicies: TypePolicies = {
  LiquidityPoolContract: {
    fields: {
      asset: {
        read(value: string) {
          if (value?.toUpperCase() === 'WXDAI') {
            return 'XDAI'
          }

          return value
        },
      },
      // apr: {
      //   read() {
      //     return '14.89'
      //   },
      // },
    },
  },
}

const chilizTypePolicies: TypePolicies = {
  LiquidityPoolContract: {
    fields: {
      asset: {
        read(value: string) {
          if (value?.toUpperCase() === 'WCHZ') {
            return 'CHZ'
          }

          return value
        },
      },
      // apr: {
      //   read() {
      //     return '14.89'
      //   },
      // },
    },
  },
}

const polygonTypePolicies: TypePolicies = {
  LiquidityPoolContract: {
    fields: {
      // apr: {
      //   read() {
      //     return '17.3'
      //   },
      // },
    },
  },
}

const dgenPolygonTypePolicies: TypePolicies = {
  LiquidityPoolContract: {
    fields: {
      // apr: {
      //   read() {
      //     return '0.00'
      //   },
      // },
    },
  },
}

const arbitrumTypePolicies: TypePolicies = {
  LiquidityPoolContract: {
    fields: {
      // apr: {
      //   read() {
      //     return '9.75'
      //   },
      // },
    },
  },
}

const lineaTypePolicies: TypePolicies = {
  LiquidityPoolContract: {
    fields: {
      // apr: {
      //   read() {
      //     return '3.8'
      //   },
      // },
    },
  },
}

const gnosis = new ApolloClient({
  uri: getUri(process.env.NEXT_PUBLIC_GRAPH_URL_GNOSIS as string),
  cache: new InMemoryCache({
    typePolicies: gnosisTypePolicies,
  }),
  connectToDevTools: false,
})

const polygon = new ApolloClient({
  uri: getUri(process.env.NEXT_PUBLIC_GRAPH_URL_POLYGON as string),
  cache: new InMemoryCache({
    typePolicies: polygonTypePolicies,
  }),
  connectToDevTools: false,
})

const dgenPolygon = new ApolloClient({
  uri: getUri(process.env.NEXT_PUBLIC_GRAPH_URL_POLYGON_DGEN as string),
  cache: new InMemoryCache({
    typePolicies: dgenPolygonTypePolicies,
  }),
  connectToDevTools: false,
})

const arbitrum = new ApolloClient({
  uri: getUri(process.env.NEXT_PUBLIC_GRAPH_URL_ARBITRUM as string),
  cache: new InMemoryCache({
    typePolicies: arbitrumTypePolicies,
  }),
  connectToDevTools: false,
})

const linea = new ApolloClient({
  uri: getUri(process.env.NEXT_PUBLIC_GRAPH_URL_LINEA as string),
  cache: new InMemoryCache({
    typePolicies: lineaTypePolicies,
  }),
  connectToDevTools: false,
})

const chiliz = new ApolloClient({
  uri: getUri(process.env.NEXT_PUBLIC_GRAPH_URL_CHILIZ as string),
  cache: new InMemoryCache({
    typePolicies: chilizTypePolicies,
  }),
  connectToDevTools: false,
})

const base = new ApolloClient({
  uri: getUri(process.env.NEXT_PUBLIC_GRAPH_URL_BASE as string),
  cache: new InMemoryCache({
    typePolicies: chilizTypePolicies,
  }),
  connectToDevTools: false,
})

const staking = new ApolloClient({
  uri: getUri(process.env.NEXT_PUBLIC_STAKING_GRAPH_URL as string),
  cache: new InMemoryCache(),
  connectToDevTools: false,
})

const stakingBase = new ApolloClient({
  uri: getUri(process.env.NEXT_PUBLIC_STAKING_BASE_GRAPH_URL as string),
  cache: new InMemoryCache(),
  connectToDevTools: true,
})

const uniswapPublic = new ApolloClient({
  // uri: getUri(process.env.NEXT_PUBLIC_UNISWAP_PUBLIC_GRAPH_URL as string),
  link: uniswapPublicGraphAuthLink.concat(
    createHttpLink({
      uri: getUri(process.env.NEXT_PUBLIC_UNISWAP_PUBLIC_GRAPH_URL as string),
    })
  ),
  cache: new InMemoryCache(),
  connectToDevTools: false,
})

const uniswapStaker = new ApolloClient({
  uri: getUri(process.env.NEXT_PUBLIC_UNISWAP_STAKER_GRAPH_URL as string),
  cache: new InMemoryCache(),
  connectToDevTools: false,
})

type Context = {
  base: ApolloClient<NormalizedCacheObject>
  gnosis: ApolloClient<NormalizedCacheObject>
  polygon: ApolloClient<NormalizedCacheObject>
  arbitrum: ApolloClient<NormalizedCacheObject>
  linea: ApolloClient<NormalizedCacheObject>
  chiliz: ApolloClient<NormalizedCacheObject>
  staking: ApolloClient<NormalizedCacheObject>
  stakingBase: ApolloClient<NormalizedCacheObject>
  uniswapPublic: ApolloClient<NormalizedCacheObject>
  uniswapStaker: ApolloClient<NormalizedCacheObject>
}

type ApolloChainId = Exclude<ChainId, ChainId.Ethereum>

export const apolloClientKeyByChainId: Record<ApolloChainId, Extract<keyof Context, 'gnosis' | 'polygon' | 'arbitrum' | 'linea' | 'chiliz' | 'base'>> = {
  [ChainId.Polygon]: 'polygon',
  [ChainId.PolygonAmoy]: 'polygon',
  [ChainId.Gnosis]: 'gnosis',
  [ChainId.Arbitrum]: 'arbitrum',
  [ChainId.ArbitrumGoerli]: 'arbitrum',
  [ChainId.Linea]: 'linea',
  [ChainId.LineaGoerli]: 'linea',
  [ChainId.Chiliz]: 'chiliz',
  [ChainId.ChilizSpicy]: 'chiliz',
  [ChainId.Base]: 'base',
  [ChainId.BaseSepolia]: 'base',
}

export const apolloClients: Context = {
  base,
  gnosis,
  polygon,
  arbitrum,
  linea,
  chiliz,
  staking,
  stakingBase,
  uniswapPublic,
  uniswapStaker,
}

export const getApolloClientByChainId = (chainId: number): ApolloClient<NormalizedCacheObject> | null => {
  const key = apolloClientKeyByChainId[chainId as keyof typeof apolloClientKeyByChainId]

  if (!key || !(key in apolloClients)) {
    return null
  }

  return apolloClients[key]
}

const Context = createContext<Context>(null as unknown as Context)

export const useApolloClients = () => {
  return useContext(Context)
}

export const ApolloClientsProvider: React.CFC = (props) => {
  const { children } = props

  return (
    <Context.Provider value={apolloClients}>
      {children}
    </Context.Provider>
  )
}

import { formatUnits } from 'viem'
import { readContract } from '@wagmi/core'
import { config } from 'wallet'
import { lpAbi } from 'contracts'
import { constants } from 'helpers'
import type { NftsQuery } from 'graph/liquidity'
import type { ChainId } from 'src/helpers/enums'


type NFTs = NftsQuery['nfts']

export type AdjustedNFT = NFTs[number] & {
  estValue: number
  profit: number
  profitRate: number
  hasContractEstValueError: boolean
  pool: NFTs[number]['pool'] & {
    isV1: boolean
    address: Address
    chainId: ChainId
  }
}

const fetchAndAdjustNftsValues = async (nfts: NFTs): Promise<(AdjustedNFT | undefined)[]> => {
  // TODO use multicall? - added on 07.07.2023 by sonatskiy
  return Promise.all(nfts.map(async (nft) => {
    const { nftId, withdrawnAmount, depositedAmount, pool } = nft

    const isV1 = Boolean(pool.coreAddresses?.includes(constants.coreV1Address))

    let profit = 0
    let profitRate = 0
    let estValue: number = +depositedAmount - +withdrawnAmount
    let hasContractEstValueError = false

    try {
      const rawEstimateValue = await readContract(config, {
        abi: lpAbi,
        address: pool.address as Address,
        chainId: pool.chainId as ChainId,
        functionName: 'nodeWithdrawView',
        args: [ Number(nftId) ],
      })

      estValue = +formatUnits(rawEstimateValue, pool.tokenDecimals)
      profit = estValue + Number(withdrawnAmount) - Number(depositedAmount)
      profitRate = profit / +depositedAmount * 100
    }
    catch (err) {
      console.error(err)
      hasContractEstValueError = true
    }

    if (estValue) {
      return {
        ...nft,
        pool: {
          ...nft.pool,
          isV1,
          address: nft.pool.address as Address,
          chainId: nft.pool.chainId as ChainId,
        },
        estValue,
        profit,
        profitRate,
        hasContractEstValueError,
      } satisfies AdjustedNFT
    }
  }))
}

export default fetchAndAdjustNftsValues

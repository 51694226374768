type Options = {
  digits?: number
  replaceSymbol?: string
  floorRounding?: boolean
  cutFractionalZero?: boolean
  significantFractionInSmallValue?: boolean
  notation?: Intl.NumberFormatOptions['notation']
}

const toLocaleString = (value: number | string, opts?: Options) => {
  const {
    digits = 2,
    replaceSymbol = ' ',
    floorRounding = true,
    cutFractionalZero = false,
    significantFractionInSmallValue = true,
    notation,
  } = opts || {}

  value = typeof value === 'string' ? parseFloat(value) : value

  if (!isFinite(value)) {
    return ''
  }

  // safe unwrap of exponent
  // 1.23e+21.toString() -> "1.23e+21"
  // 1.23e-21.toString() -> "1.23e-21"
  // 1.23e+21.toLocaleString('en', { useGrouping: false, minimumSignificantDigits: 1 }) -> "1230000000000000000000"
  // 1.23e-21.toLocaleString('en', { useGrouping: false, minimumSignificantDigits: 1 }) -> "0.00000000000000000000123"
  const [ int, fraction ] = value.toLocaleString('en', { useGrouping: false, minimumSignificantDigits: 1 }).split('.')

  let preFormattedValue = value

  const isSmallValueFormatting = Math.abs(value) < 1 && significantFractionInSmallValue

  if (fraction?.length > digits && floorRounding) {
    const significantAddition = isSmallValueFormatting
      ? fraction.match(/[1-9]/)?.index || 0
      : 0

    preFormattedValue = parseFloat(`${int}.${fraction.substring(0, digits + significantAddition)}`)
  }

  const formatOptions: Intl.NumberFormatOptions = {
    notation,
    roundingMode: 'trunc',
  }

  if (isSmallValueFormatting) {
    formatOptions.minimumSignificantDigits = digits || 1
    formatOptions.maximumSignificantDigits = digits || 1
  }
  else {
    formatOptions.minimumFractionDigits = digits
    formatOptions.maximumFractionDigits = digits
  }

  const result = preFormattedValue
    .toLocaleString('en', formatOptions)
    // narrow non-breaking space, equals to "thin space", which is recommended international thousand delimiter
    .replace(/,/g, replaceSymbol)

  if (cutFractionalZero && result.includes('.')) {
    return result.replace(/\.?0+$/, '')
  }

  return result
}

export default toLocaleString

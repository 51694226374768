import clsx from 'clsx'
import { openModal } from '@locmod/modal'
import { Message } from '@locmod/intl'
import { Button } from 'components/inputs'

import messages from './messages'


type AddTokenSectionProps = {
  className?: string
}

const AddTokenSection: React.FC<AddTokenSectionProps> = (props) => {
  const { className } = props

  return (
    <div className={clsx(className, 'relative py-[1.875rem] px-6 border-t border-b border-bg-20 border-b-transparent text-center')}>
      <span className="sm:hidden absolute top-0 left-1/2 -translate-y-1/2 -translate-x-1/2 size-2 aspect-square rounded-full bg-brand-50 shadow-[0_0_0_2px_#fff]" />
      <Message className="text-label-16 font-medium text-grey-80" value={messages.title} />
      <Button
        className="w-full mt-4"
        styling="tertiary"
        size={44}
        title={messages.button}
        rightIcon="tokens/azuro"
        onClick={() => openModal('AddAzurToWalletModal')}
      />
    </div>
  )
}

export default AddTokenSection
